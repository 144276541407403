<template>
  <div id="ProducerExpertRecurrence">
    <QsCard :qlik-ids="['JQtNK']" style="grid-column: 1 / span 1" />
    <QsCard :qlik-ids="['cnAfAq']" style="grid-column: 2 / span 2" />
    <QsCard :qlik-ids="['xGBfV']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['yEyKqPy']" style="grid-column: 3 / span 1" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default {
  name: "ProducerExpertRecurrence",
  components: { QsCard },
  mounted() {
    this.$mixpanel.track("View retention page");
  }
};
</script>

<style scoped>
#ProducerExpertRecurrence {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 300px 330px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
